import React, { useState, useEffect } from "react";
//import { FieldsByTemplate } from "./api/documentType";
import { GetDocumentTypeTemplateByCompany } from "./api/documentType";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { GetDocumentTypeTemplateById } from "./api/documentType";
import TemplateDetails from "./screens/document/views/templateDetails";
import TemplateFields from "./screens/document/views/templateFields";
import LLMFIelds from "./screens/document/views/llmFields";
import { useParams } from "react-router-dom";
import InputSources from "./screens/sources/InputSources";
import OutputSources from "./screens/sources/OutputSources";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { template } from "lodash";
import { Typography } from "@mui/material";

//import NewTemplateForm from "./screens/forms/newTemplateForm";
function TemplateManagement() {
  const params = useParams();
  const [templates, setTemplates] = useState([]);
  //const [summaryFieldDocuments, setSummaryFieldDocuments] = useState([]);
  // const [lineItemsdDocuments, setLineItemsdDocuments] = useState([]);
  const [templateType, setTemplateType] = useState();
  const [templateTypeName, setTemplateTypeName] = useState("");
  const [templateTypesList, setTemplateTypesList] = useState("");
  const [templateDetails, setTemplateDetails] = useState([]);
  // const [displayNewTemplateForm, setDisplayNewTemplateForm] = useState("none");

  useEffect(() => {
    setTemplateType(params.id);
    const fetchData = async () => {
      let Tdetails = await GetDocumentTypeTemplateById(params.id);
      setTemplateDetails(Tdetails);
    };
    fetchData();
  }, []);

  {
  }

  if (templateDetails.length < 1) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "90vh" }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  return (
    <>
      {" "}
      <Grid
        container
        spacing={0}
        direction="column"
        //alignItems="center"
        justifyContent="start"
        style={{ minHeight: "90vh", padding: "5vh", paddingTop: "1vh" }}
      >
        <Grid item xs="6">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="large" />}
            aria-label="breadcrumb"
            sx={{ mb: 0 }}
          >
            <Link
              underline="hover"
              key="1"
              to="/template-configuration"
              style={{ textDecoration: "none" }}
            >
              Template Management
            </Link>

            <Typography key="3" color="text.primary">
              {templateDetails.templateName}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs="12">
          <TemplateDetails data={templateDetails} templateType={templateType} />
        </Grid>
        <Grid item xs="12">
          <br></br>
        </Grid>
        <Grid item>
          {templateDetails.model.includes("LLM") ? (
            <LLMFIelds
              parentName={"LLM"}
              templateType={templateType}
              templateModel={templateDetails.model}
            />
          ) : (
            <>
              <Grid item md="12">
                <Box>
                  <TemplateFields
                    parentName={"SummaryFields"}
                    templateType={templateType}
                    templateModel={templateDetails.model}
                  />
                </Box>
              </Grid>
              <Grid item md="12">
                <Box>
                  <TemplateFields
                    parentName={"LineItems"}
                    templateType={templateType}
                    templateModel={templateDetails.model}
                  />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item>
          <InputSources templateID={templateType} />
        </Grid>
        <Grid item>
          <OutputSources templateID={templateType} />
        </Grid>
      </Grid>
    </>
  );
}
export default TemplateManagement;
