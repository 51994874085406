import React, { useState, useEffect } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { BarChart } from "@mui/x-charts/BarChart";
function LineCustomChart(data) {
  const [yData, setYData] = useState(getTemplateProcessed(data.data));
  const [xData, setXData] = useState(getTemplateNames(data.data));
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  function getTemplateNames(arr) {
    return arr
      .filter((item) => item.processedDocuments > 0)
      .map((item) => item.templateName);
  }
  function getTemplateProcessed(arr) {
    return arr
      .filter((item) => item.processedDocuments > 0)
      .map((item) => item.failedDocuments);
  }
  useEffect(() => {
    setYData(getTemplateProcessed(data.data));
    setXData(getTemplateNames(data.data));
  }, [data]);
  useEffect(() => {}, []);
  if (data.length < 1) {
    return <CircularProgress color="secondary" />;
  }
  return (
    <BarChart
      xAxis={[
        {
          scaleType: "band",
          data: xData,
          min: 1,
          categoryGapRatio: 0.5,
        },
      ]}
      series={[{ data: yData }]}
      width={matches ? 900 : 350}
      height={300}
    />
  );
}

export default LineCustomChart;
