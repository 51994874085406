import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetTemplates } from "../../api/sources";

import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { DeleteDocumentTypeTemplate } from "../../api/documentType";
import { Add } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";

const columnHelper = createColumnHelper();

const TemplateConfiguration = () => {
  const [templates, setTemplates] = useState([]);
  const navigate = useNavigate();
  const confirm = useConfirm();


  const fetchData = async () => {
    let Tdetails = await GetTemplates();
    if (Tdetails.data.length < 1) {
      setTemplates([]);
    } else {
      setTemplates(Tdetails.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Define the table columns
  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "id",
      //   header: "ID",
      // },
      {
        accessorKey: "templateName",
        header: ({ column }) => (
          <span
            onClick={column.getToggleSortingHandler()}
            style={{ cursor: "pointer" }}
          >
            Template Name{" "}
            {column.getIsSorted()
              ? column.getIsSorted() === "asc"
                ? " 🔼"
                : " 🔽"
              : ""}
          </span>
        ),
        cell: ({ row }) => (
          <a href={`/#/template-management/${row.original.id}`}>
            {row.original.templateName}
          </a>
        ),
      },
      {
        accessorKey: "descriptionTemplate",
        header: "Description",
      },
      ,
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: (props) => (
          <IconButton
            color="info"
            onClick={() => handleDelete(props.row.original.id)}
          >
            <DeleteIcon />
          </IconButton>
        ),
      }),
    ],
    []
  );

  const [sorting, setSorting] = useState([]);
  const [filter, setFilter] = useState("");

  // Use the table hook from @tanstack/react-table
  const table = useReactTable({
    data: templates,
    columns,
    getCoreRowModel: getCoreRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    // getSortedRowModel: getSortedRowModel(),
    // getFilteredRowModel: getFilteredRowModel(),
    // state: {
    //   sorting,
    //   globalFilter: filter,
    // },
    // onSortingChange: setSorting,
    // onGlobalFilterChange: setFilter,
  });

  const handleClick = () => {
    navigate("/create-template");
  };

  const handleClickInputSources = () => {
    navigate("/inputSources");
  };

  const handleDelete = async (id) => {
    confirm({
      title: "Delete Template",
      description: "Are you sure?",
      confirmationText: "Delete",
      cancellationText: "Cancel",
      confirmationButtonProps:{className:'xrx-btn btn-primary'},
      cancellationButtonProps:{className:'xrx-btn btn-secondary'},
    })
    .then( async () => {
     const result = await DeleteDocumentTypeTemplate(id);

    if (result.status === 200) {
      fetchData();
    } else {
      console.log("Error found while deleting template.");
    }
  })
    .catch( ()=> {
      console.log("Delete cancelled");
    });
  };


  return (
    <div className="container">
      <h3>Configure Templates</h3>

      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <div>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Grid container spacing={2} mt={1} justifyContent="left">
        <Grid item>
          <Button variant="contained" onClick={handleClick} color="secondary">
            <Add sx={{ mr: 2 }} /> Add Template
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default TemplateConfiguration;
