import "./App.css";
import "@fontsource/roboto-condensed";
import { Outlet } from "react-router-dom";
import React, { useEffect } from "react";
import NavBar from "./navbar";
import { ThemeProvider } from "@mui/material/styles";
import Login from "./login";
import customTheme from "./customTheme";
//import jwt_decode from "jwt-decode";
import { logout } from "./api/auth";
import { jwtDecode } from "jwt-decode";
import "./fonts/xGlyph-Regular.woff";
import "./fonts/xGlyph-Regular.ttf";
import "./fonts/xGlyph-Regular.otf";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ConfirmProvider } from "material-ui-confirm";
function App() {
  let theme = customTheme();

  useEffect(() => {
    document.title = "AnyDoc";
  });

  if (!sessionStorage.token) {
    return <Login />;
  }

  return (
    <>
      <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <nav>
            <NavBar />
          </nav>
          <div className="main-content">
            <Outlet />
          </div>
          <div className="main-btn"></div>
        </LocalizationProvider>
        </ConfirmProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
