import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { SaveTemplate } from "../../api/documentType";
import { Snackbar } from "@mui/material";
import { Navigate } from "react-router-dom";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { GetModel } from "../../api/documentType";
import MenuItem from "@mui/material/MenuItem";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

function CreateTemplate() {
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const navigate = useNavigate();
  const [templateTempName, setTemplateTempName] = useState("");
  const [tempConfidence, setTempConfidence] = useState("");
  const [tempDescription, setTempDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState(
    "Could not create template, please validate and try again."
  );
  const [models, setModels] = useState([]);
  const [modelName, setModelName] = useState([]);
  const [modelId, setModelId] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [disableConfidence, setDisableConfidence] = useState(false);

  const validKeyForPayment = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "Backspace",
    "Tab",
  ];
  useEffect(() => {
    (async () => {
      const modelList = await GetModel();
      setModels(modelList);
      setModelName(modelList[0].modelName);
      setModelId(modelList[0].id);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const modelItems = models.map((model) => (
        <MenuItem value={model.modelName} data-key={model.id} name={model.id}>
          {model.modelName}
        </MenuItem>
      ));
      setModelList(modelItems);
    })();
  }, [models]);

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };
  const handleSave = async () => {
    if (templateTempName.length < 1) {
      setErrorMessage("Template name is required.");
      setOpenSnack(true);
      return;
    }
    if (tempConfidence === null) {
      setTempConfidence(0);
    }
    const id = null;
    const templateName = templateTempName;
    const confidence = tempConfidence;
    const descriptionTemplate = tempDescription;
    const llmTemperature = null;
    const llmMaxTokenOutput = null;
    const llmBasePrompt = null;
    const llmPrompt = null;
    const model = modelName;
    const searchCriteria = null;
    const ignoreFieldConfiguration = null;
    const templateToBuildJSONString = null;
    const analyzeConfiguration = null;

    const createCurrentTemplate = await SaveTemplate(
      id,
      templateName,
      llmTemperature,
      llmMaxTokenOutput,
      llmBasePrompt,
      llmPrompt,
      model,
      searchCriteria,
      confidence,
      ignoreFieldConfiguration,
      templateToBuildJSONString,
      analyzeConfiguration,
      descriptionTemplate
    );
    if (createCurrentTemplate.status === 200) {
      console.log(createCurrentTemplate.data.id);
      setErrorMessage("Template created successfully.");
      setOpenSnack(true);
      navigate("/template-management/" + createCurrentTemplate.data.id);
    } else {
      setErrorMessage(
        "Could no update template details, please validate and try again."
      );
      setOpenSnack(true);
    }
  };

  if (models.length < 1 || loading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "90vh" }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  return (
    <>
      <Grid container spacing={2} justifyContent="start" ml={2} mr={2}>
        <Grid item xs={12}>
          <h6>Create Template</h6>
        </Grid>

        <Grid item md={4}>
          <FormControl>
            <TextField
              id="outlined-required"
              label="Template Name"
              value={templateTempName}
              color="secondary"
              sx={{ width: 400 }}
              onChange={(e) => {
                setTemplateTempName(e.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl>
            <TextField
              type="number"
              id="outlined-required"
              label="Confidence"
              value={tempConfidence}
              color="secondary"
              sx={{ width: 400 }}
              onChange={(e) => {
                setTempConfidence(e.target.value);
              }}
              onKeyDown={(e) => {
                if (!validKeyForPayment.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              disabled={disableConfidence}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl>
            <InputLabel id="model-simple-select-label" color="info">
              Model
            </InputLabel>
            <Select
              labelId="model-select-label"
              id="model-select"
              label="Model"
              value={modelName}
              onChange={(e) => {
                setModelName(e.target.value);
                //  returnIndex(e.target.value);
                //setFileType(e.target.hasOwnProperty("data-key"));
              }}
              color="info"
              sx={{ width: 400 }}
            >
              {modelList}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl>
            <TextField
              type="text"
              id="outlined-required"
              label="Description"
              value={tempDescription}
              color="secondary"
              sx={{ width: 400 }}
              onChange={(e) => {
                setTempDescription(e.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12} mt={2}>
          <Box sx={{ width: 400 }}>
            {" "}
            <Button
              variant="outlined"
              color="info"
              class="xrx-btn btn-default btn-block"
              component="label"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Grid>
        <Grid>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={openSnack}
            onClose={handleCloseSnack}
            message={errorMessage}
            autoHideDuration={3000}
          />
        </Grid>
      </Grid>
    </>
  );
}
export default CreateTemplate;
