import axiosApiInstance from "./axiosApiInstance";
const apiURL = process.env.REACT_APP_AUTH_API_URL;

//--## Application
export async function GetApplicationType() {
  const url = apiURL + "/api/Application/GetApplicationType";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetApplications_catch", error.message);
      return [];
    });
}

export async function GetApplicationCompanyById(id) {
  const url = apiURL + "/api/Application/GetApplicationCompanyById";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  const body = {
    ApplicationCompanyID: id,
  };

  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetApplicationCompanyById_catch", error.message);
      return [];
    });
}

export async function InsertApplicationCompany(
  TypeID,
  Name,
  Tenant,
  Client,
  Username,
  Pass
) {
  const url = apiURL + "/api/Application/InsertApplicationCompany";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  const body = {
    ApplicationTypeID: TypeID,

    ApplicationCompanyName: Name,
    TenantID: Tenant,

    ClientID: Client,

    UserName: Username,
    Password: Pass,
  };

  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetApplicationCompanyById_catch", error.message);
      return [];
    });
}

export async function UpdateApplicationCompany(
  ID,
  TypeID,
  Name,
  Tenant,
  Client,
  Username,
  Pass
) {
  const url = apiURL + "/api/Application/UpdateApplicationCompany";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  const body = {
    ApplicationCompanyID: ID,
    ApplicationTypeID: TypeID,

    ApplicationCompanyName: Name,
    TenantID: Tenant,

    ClientID: Client,

    UserName: Username,
    Password: Pass,
  };

  return await axiosApiInstance
    .put(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetApplicationCompanyById_catch", error.message);
      return [];
    });
}

export async function DeleteApp(id) {
  const url = apiURL + "/api/Application/DeleteApplicationCompany/" + id;
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .delete(url, { headers })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function GetAppById(id) {
  const url = apiURL + "/api/Application/GetApplicationCompanyByID";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  const body = {
    ApplicationCompanyID: id,
  };

  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetApplicationCompany_catch", error.message);
      return [];
    });
}

export async function GetApplicationCompany() {
  const url = apiURL + "/api/Application/GetApplicationCompany";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };

  const body = {
    PageNo: 1,
    PageSize: 25,
    ColumnOrder: "ApplicationName ascending",
    Filters: {
      GeneralFilter: "",
    },
  };

  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("GetApplicationCompany_catch", error.message);
      return [];
    });
}

//--## Templates
