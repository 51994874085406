import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AddConnectorToSource,
  DeleteSourceWithFetch,
  GetConnectorSourceToSelect,
  GetDocumentsConnectorSource,
} from "../../api/sources";

import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";

import { Add } from "@mui/icons-material";

import { useConfirm } from "material-ui-confirm";

import "./sources.css";

const columnHelper = createColumnHelper();

const OutputSources = (data) => {
  const navigate = useNavigate();

  const [selectedConnector, setSelectedConnector] = useState("");
  const [outputConnector, setOutputConnector] = useState([]);
  const [connectorSource, setConnectorSource] = useState([]);
  const [deleteSource, setDeleteSource] = useState(null);
  const [templateID, setTemplateID] = useState(data.templateID);
  const confirm = useConfirm();

  //==/api/DocumentType/GetConnectorSource
  useEffect(() => {
    // {"qty":1,"results":[{"id":5,"sourceName":"Email BOL Input","applicationCompanyID":2,"connectorTypeID":3,"directionTypeID":1,"applicationCompanyName":"AnyDoc U1","directionTypeName":"IN","connectorTypeName":"Email"}]}
    (async () => {
      const data = await GetDocumentsConnectorSource(2, "", templateID);
      console.log(`ConnectorSource: ${data}`);

      if (data?.qty < 1) {
        setOutputConnector([]);
      } else {
        const items = data.results.map((item) => ({
          id: item.id,
          sourceName: item.sourceName,
        }));
        setOutputConnector(items);
      }
    })();
  }, []);

  //==/api/DocumentType/GetConnectorSourceToSelect
  useEffect(() => {
    // [{"connectorSourceID":1,"sourceName":"Sharepoint BOL Input"}]
    (async () => {
      const data = await GetConnectorSourceToSelect(0, 2);

      if (data.length < 1) {
        setConnectorSource([]);
      } else {
        const outputSourceItems = data.map((source) => (
          <MenuItem
            value={source.connectorSourceID}
            key={source.connectorSourceID}
          >
            {source.sourceName}
          </MenuItem>
        ));

        setConnectorSource(outputSourceItems);
      }
    })();
  }, []);

  const updateSources = async () => {
    //--the combobox
    const data = await GetConnectorSourceToSelect(0, 2);
    if (data.length < 1) {
      setConnectorSource([]);
    } else {
      const outputSourceItems = data.map((source) => (
        <MenuItem
          value={source.connectorSourceID}
          key={source.connectorSourceID}
        >
          {source.sourceName}
        </MenuItem>
      ));
      setConnectorSource(outputSourceItems);
    }

    //--the grid
    const dataGrid = await GetDocumentsConnectorSource(2, "", templateID);

    if (dataGrid?.qty < 1) {
      setOutputConnector([]);
    } else {
      const items = dataGrid.results.map((item) => ({
        id: item.id,
        sourceName: item.sourceName,
      }));
      setOutputConnector(items);
    }
  };

  const handleAddSource = async (e) => {
    const data = await AddConnectorToSource(selectedConnector, templateID);
    updateSources();
  };

  const handleDelete = async (id) => {

    confirm({
      title: "Delete Source",
      description: "Are you sure?",
      confirmationText: "Delete",
      cancellationText: "Cancel",
      confirmationButtonProps:{className:'xrx-btn btn-primary'},
      cancellationButtonProps:{className:'xrx-btn btn-secondary'},
    })
    .then( async () => {

    const result = await DeleteSourceWithFetch(id, templateID);

    if (result === 0) {
      updateSources();
    } else {
      console.log("Error found while deleting source.");
    }
  })
  .catch( ()=> {
    console.log("Delete cancelled");
  });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "sourceName",
        header: ({ column }) => (
          <span
            onClick={column.getToggleSortingHandler()}
            style={{ cursor: "pointer" }}
          >
            Source Name{" "}
            {column.getIsSorted()
              ? column.getIsSorted() === "asc"
                ? " 🔼"
                : " 🔽"
              : ""}
          </span>
        ),
        cell: ({ row }) => (
          <a href={`/#/output-sources`}>{row.original.sourceName}</a>
        ),
      },
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: (props) => (
          <IconButton
            color="info"
            onClick={() => handleDelete(props.row.original.id)}
          >
            <DeleteIcon />
          </IconButton>
        ),
      }),
    ],
    []
  );

  const [sorting, setSorting] = useState([]);
  const [filter, setFilter] = useState("");

  const table = useReactTable({
    data: outputConnector,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="container">
      <h3>Output Sources</h3>

      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <div>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <Grid item md={4} xs={4} py={2}>
        {connectorSource.length > 0 ? (
          <Box
            sx={{
              border: 1,
              borderColor: "grey.500",
              padding: 2,
              marginTop: 2,
              width: "400px",
            }}
          >
            <Typography variant="h5" component="div">
              Select output sources
            </Typography>

            <FormControl>
              <InputLabel>Connector Name</InputLabel>
              <Select
                label="Select an Option"
                value={selectedConnector}
                onChange={(e) => setSelectedConnector(e.target.value)}
                sx={{ width: 300 }}
              >
                {connectorSource}
              </Select>
              <Button
                variant="contained"
                onClick={handleAddSource}
                color="secondary"
                sx={{ ml: 2, mt: 2 }}
              >
                <Add /> Add Source
              </Button>
            </FormControl>
          </Box>
        ) : (
          <p>No more connectors!</p>
        )}
      </Grid>
    </div>
  );
};

export default OutputSources;
