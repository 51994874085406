import React, { useState, useEffect } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { CircularProgress } from "@mui/material";
export default function BarCustomChart(data) {
  const [yData, setYData] = useState(getTemplateCredits(data.data));
  const [xData, setXData] = useState(getTemplateNames(data.data));
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  function getTemplateNames(arr) {
    return arr
      .filter((item) => item.processedDocuments > 0)
      .map((item) => item.templateName);
  }
  function getTemplateCredits(arr) {
    return arr
      .filter((item) => item.processedDocuments > 0)
      .map((item) => item.credits);
  }
  useEffect(() => {
    setYData(getTemplateCredits(data.data));
    setXData(getTemplateNames(data.data));
  }, [data]);
  useEffect(() => {}, []);
  if (data.length < 1) {
    return <CircularProgress color="secondary" />;
  }
  return (
    <BarChart
      xAxis={[
        {
          scaleType: "band",
          data: xData,
          min: 1,
          categoryGapRatio: 0.5,
        },
      ]}
      series={[{ data: yData, color: "#3d58c1" }]}
      width={matches ? 900 : 350}
      height={300}
    />
  );
}
