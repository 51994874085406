import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Login from "./login";
import Twofa from "./twofa";
import Dashboard from "./dashboard";
import Profile from "./profile";
import Pending from "./pending";
import Completed from "./completed";
import ChangePassword from "./changePassword";
import ForgotPassword from "./forgotPassword";
import ForgotPasswordTwoFa from "./forgotPasswordTwoFa";
import UploadFiles from "./uploadFiles";
import Document from "./document";
import TemplateManagement from "./templateManagement";
import UserManagement from "./screens/admin/userManagement";
import {
  BrowserRouter,
  Route,
  Routes,
  HashRouter,
  useSearchParams,
} from "react-router-dom";
import CreateUser from "./screens/admin/createUser";
import { Navigate } from "react-router-dom";
import NewTemplateForm from "./screens/forms/newTemplateForm";
import NewPassword from "./newPassword";
import Forbidden from "./forbidden";
import UserLogin from "./userLogin";

import TemplateConfiguration from "./screens/templates/TemplateConfiguration";
import InputSources from "./screens/sources/InputSources";
import OutputSources from "./screens/sources/OutputSources";
import CreateTemplate from "./screens/templates/createTemplate";
import OutputSourcesConfiguration from "./screens/sources/outputSourcesConfiguration";
import InputSourcesConfiguration from "./screens/sources/inputSourcesConfiguration";

import { ConfirmProvider, useConfirm } from "material-ui-confirm";
//IMPORT SETUP
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/user-login" element={<UserLogin />} />
      <Route path="/twofa" element={<Twofa />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/forgotPasswordTwoFa" element={<ForgotPasswordTwoFa />} />
      <Route path="/newPassword" element={<NewPassword />} />
      <Route path="/forbidden" element={<Forbidden />} />
      <Route path="/" element={<App />}>
        <Route index element={<Navigate to="/dashboard" replace />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/pending" element={<Pending />} />
        <Route path="/completed" element={<Completed />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route
          path="/upload"
          element={
            <ConfirmProvider>
              <UploadFiles />
            </ConfirmProvider>
          }
        />
        <Route path="/document/:id" element={<Document />} />
        <Route path="/template-management/:id" element={<TemplateManagement /> } />
        <Route path="/create-template" element={<CreateTemplate />} />
        <Route
          path="/users"
          element={
            <ConfirmProvider>
              <UserManagement />
            </ConfirmProvider>
          }
        />
        <Route path="/addusers" element={<CreateUser />} />
        <Route path="/addtemplate" element={<NewTemplateForm />} />

        <Route
          path="/template-configuration"
          element={<TemplateConfiguration />}
        />
        <Route path="/input-sources" element={<InputSourcesConfiguration />} />
        <Route
          path="/output-sources"
          element={<OutputSourcesConfiguration />}
        />
      </Route>
    </Routes>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
