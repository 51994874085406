import DateRangePicker from "../../components/dateRangePicker";
import BarCustomChart from "../charts/BarCustomChart";
import PieCustomChart from "../charts/PieCustomChart";
import LineCustomChart from "../charts/LineCustomChart";
import { UsageDashboard } from "../../api/dashboard";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";
function GraphCard(props) {
  const [usageData, setUsageData] = useState([]);

  const handleData = (childData) => {
    fetchData(
      childData.startDate.$d.toISOString().split("T")[0],
      childData.endDate.$d.toISOString().split("T")[0]
    );
  };
  useEffect(() => {
    fetchData(dayjs().subtract(1, "month"), dayjs());
  }, []);
  const fetchData = async (s, e) => {
    let Tdata = await UsageDashboard(s, e);
    setUsageData(Tdata);
  };
  if (usageData.length < 1) {
    return <CircularProgress color="secondary" />;
  }
  return (
    <div class="dashboard-card">
      <div class="xrx-card ">
        <div class="card-1line-title">
          <h6> {props.tittle}</h6>
          <div class="right-object">
            <DateRangePicker onData={handleData} />
          </div>
        </div>
        <div class="card-body pt-0" className="dashboard-graph-card">
          {props.type.toString().includes("bar") ? (
            <BarCustomChart data={usageData} />
          ) : (
            ""
          )}
          {props.type.toString().includes("pie") ? (
            <PieCustomChart data={usageData} />
          ) : (
            ""
          )}
          {props.type.toString().includes("line") ? (
            <LineCustomChart data={usageData} />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
export default GraphCard;
