import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import { GetTypeOfGrouping } from "../../../api/documentType";
import { GetDataType } from "../../../api/documentType";
import MenuItem from "@mui/material/MenuItem";
import SaveIcon from "@mui/icons-material/Save";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { FieldsByTemplate } from "../../../api/documentType";
import { Button } from "@mui/material";
import { Snackbar } from "@mui/material";
import { SaveFieldTemplate } from "../../../api/documentType";
import { DeleteFieldTemplate } from "../../../api/documentType";
import { TextBox } from "@react-pdf-viewer/core";

function LLMFIelds(data) {
  const [summaryFieldDocuments, setSummaryFieldDocuments] = useState([]);
  const parentName = data.parentName;
  const templateType = data.templateType;
  const [openSnack, setOpenSnack] = useState(false);
  const [loading, setLoading] = useState(true);
  const [parentID, setParentID] = useState("");
  const model = data.templateModel;

  const [errorMessage, setErrorMessage] = useState(
    "Can't add new item until current one is completed."
  );
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  function filterByParentName(documents, parentName) {
    return documents.filter((document) => document.parentName === parentName);
  }
  const fetchData = async () => {
    const URLData = await FieldsByTemplate(data.templateType);

    setSummaryFieldDocuments(URLData);
  };
  useEffect(() => {
    fetchData();
  }, [data]);
  //--TYPE OF GROUPING
  const [typeOfGroupings, setTypeOfGroupings] = useState([]);

  const [typeOfGroupingList, setTypeOfGroupingList] = useState([]);

  //--DATA TYPE
  const [dataTypes, setDataTypes] = useState([]);

  const [dataTypesList, setDataTypesList] = useState([]);

  //Edited fields
  const [editedFieldName, setEditedFieldName] = useState("");
  const [editedConfidence, setEditedConfidence] = useState("");
  const [editedSelected, setEditedSelected] = useState(true);
  const [editedLabel, setEditedLabel] = useState("");
  const [editedDataTypeName, setEditedDataTypeName] = useState(0);

  useEffect(() => {
    (async () => {
      fetchTemplateFieldsData();
      const typeOfGroupingList = await GetTypeOfGrouping();
      setTypeOfGroupings(typeOfGroupingList);
    })();
  }, []);

  const fetchTemplateFieldsData = async () => {
    setLoading(true);
    const dataTypeList = await GetDataType();
    setDataTypes(dataTypeList);
    setLoading(false);
  };

  const fetchFields = async () => {
    const URLData = await FieldsByTemplate(data.templateType);

    setSummaryFieldDocuments(URLData);
  };
  useEffect(() => {}, [dataTypes]);

  function returnIndex(dataTypeName) {
    const index = dataTypes.find((o) => o.dataTypeName === dataTypeName);
    setEditedDataTypeName(index.id);
    return index.id;
  }

  useEffect(() => {
    (async () => {
      const dataTypeItems = dataTypes.map((datatype) => (
        <MenuItem
          value={datatype.dataTypeName}
          data-key={datatype.id}
          name={datatype.id}
        >
          {datatype.dataTypeName}
        </MenuItem>
      ));
      setDataTypesList(dataTypeItems);
    })();
  }, [dataTypes]);

  useEffect(() => {
    (async () => {
      const typeOfGroupingItems = typeOfGroupings.map((typeOfGrouping) => (
        <MenuItem
          value={typeOfGrouping.typeOfGroupingName}
          data-key={typeOfGrouping.id}
          name={typeOfGrouping.id}
        >
          {typeOfGrouping.typeOfGroupingName}
        </MenuItem>
      ));
      setTypeOfGroupingList(typeOfGroupingItems);
    })();
  }, [typeOfGroupings]);

  useEffect(() => {
    //setSummaryFieldDocuments(data);
  }, [data]);
  useEffect(() => {}, [summaryFieldDocuments]);

  const getValueById = (id, key) => {
    const item = summaryFieldDocuments.find((item) => item.id === id);
    return item ? item[key] : null;
  };

  const handleSave = async (event, id) => {
    setParentID(null);

    if (editedFieldName.length < 1) {
      setErrorMessage("Name value must not be empty.");
      setOpenSnack(true);
    }
    if (editedDataTypeName < 1) {
      setErrorMessage("Data type value must not be empty");
      setOpenSnack(true);
    } else {
      const id = null;
      const documentTypeTemplateId = data.templateType;
      const selected = editedSelected;
      const fieldName = editedFieldName;
      const prompt = editedLabel;
      const typeOfGroupingId = 1;
      const parentId = null;
      const confidence = 0;
      const dataTypeID = editedDataTypeName;
      const labelName = editedLabel;

      const updateTempField = await SaveFieldTemplate(
        id,
        documentTypeTemplateId,
        selected,
        fieldName,
        prompt,
        typeOfGroupingId,
        parentId,
        confidence,
        dataTypeID,
        labelName
      );
      if (updateTempField === 200) {
        fetchData();
        setErrorMessage("New field created successfully.");
        setOpenSnack(true);
      } else {
        setErrorMessage("Could not create new field.");
        setOpenSnack(true);
      }
    }
  };
  const handleConfidence = async (event, id) => {
    //const newEmail = returnEmail(id);
    const documentTypeTemplateId = null;
    const selected = getValueById(id, "selected");
    const fieldName = getValueById(id, "fieldName");
    const prompt = getValueById(id, "prompt");
    const typeOfGroupingId = getValueById(id, "typeOfGroupingID");
    const parentId = getValueById(id, "parentId");
    const confidence = event.target.value;
    const dataTypeID = getValueById(id, "dataTypeID");
    const labelName = getValueById(id, "labelName");

    const updateCurrentConfidence = await SaveFieldTemplate(
      id,
      documentTypeTemplateId,
      selected,
      fieldName,
      prompt,
      typeOfGroupingId,
      parentId,
      confidence,
      dataTypeID,
      labelName
    );
    if (updateCurrentConfidence === 200) {
      fetchFields();
      setErrorMessage("Confidence updated successfully.");
      setOpenSnack(true);
    } else {
      setErrorMessage("Could not update confidence.");
      setOpenSnack(true);
    }
  };
  const handleSelected = async (event, id) => {
    //const newEmail = returnEmail(id);
    const documentTypeTemplateId = null;
    const selected = event.target.checked;
    const fieldName = getValueById(id, "fieldName");
    const prompt = getValueById(id, "prompt");
    const typeOfGroupingId = getValueById(id, "typeOfGroupingID");
    const parentId = getValueById(id, "parentId");
    const confidence = getValueById(id, "confidence");
    const dataTypeID = getValueById(id, "dataTypeID");
    const labelName = getValueById(id, "labelName");

    const updateCurrentConfidence = await SaveFieldTemplate(
      id,
      documentTypeTemplateId,
      selected,
      fieldName,
      prompt,
      typeOfGroupingId,
      parentId,
      confidence,
      dataTypeID,
      labelName
    );
    if (updateCurrentConfidence === 200) {
      fetchFields();
      setErrorMessage("Selected updated successfully.");
      setOpenSnack(true);
    } else {
      setErrorMessage("Could not update selected.");
      setOpenSnack(true);
    }
  };
  const handleDataTypeName = async (event, id) => {
    //const newEmail = returnEmail(id);
    const documentTypeTemplateId = null;
    const selected = getValueById(id, "selected");
    const fieldName = getValueById(id, "fieldName");
    const prompt = getValueById(id, "prompt");
    const typeOfGroupingId = getValueById(id, "typeOfGroupingID");
    const parentId = getValueById(id, "parentId");
    const confidence = getValueById(id, "confidence");
    const dataTypeID = returnIndex(event.target.value);
    const labelName = getValueById(id, "labelName");

    const updateCurrentConfidence = await SaveFieldTemplate(
      id,
      documentTypeTemplateId,
      selected,
      fieldName,
      prompt,
      typeOfGroupingId,
      parentId,
      confidence,
      dataTypeID,
      labelName
    );
    if (updateCurrentConfidence === 200) {
      fetchFields();
      setErrorMessage("Data type value updated successfully.");
      setOpenSnack(true);
    } else {
      setErrorMessage("Could not update data type value.");
      setOpenSnack(true);
    }
  };

  const handleDelete = (event, id) => {
    setSummaryFieldDocuments(
      summaryFieldDocuments.filter((item) => item.id !== id)
    );
  };
  const handleFieldDelete = (event, id) => {
    DeleteFieldTemplate(id);
    setSummaryFieldDocuments(
      summaryFieldDocuments.filter((item) => item.id !== id)
    );
  };
  const addNew = () => {
    const isIdFound = (id) => {
      return summaryFieldDocuments.some((item) => item.id === id);
    };
    const valid = isIdFound(0);

    if (!valid) {
      const newItem = {
        id: 0,
        documentTypeTemplateId: templateType,
        selected: true,
        fieldName: "",
        confidence: "",
        dataTypeName: "",
        parentName: parentName,
      };
      setSummaryFieldDocuments([newItem, ...summaryFieldDocuments]);
    } else {
      setErrorMessage("Can't add new item until current one is completed.");
      setOpenSnack(true);
    }
  };
  const handlePrompt = async (event, id) => {
    //const newEmail = returnEmail(id);
    const documentTypeTemplateId = null;
    const selected = getValueById(id, "selected");
    const fieldName = getValueById(id, "fieldName");
    const prompt = getValueById(id, "prompt");
    const typeOfGroupingId = getValueById(id, "typeOfGroupingID");
    const parentId = getValueById(id, "parentId");
    const confidence = getValueById(id, "confidence");
    const dataTypeID = getValueById(id, "dataTypeID");
    const labelName = event.target.value;

    const updateCurrentConfidence = await SaveFieldTemplate(
      id,
      documentTypeTemplateId,
      selected,
      fieldName,
      prompt,
      typeOfGroupingId,
      parentId,
      confidence,
      dataTypeID,
      labelName
    );
    if (updateCurrentConfidence === 200) {
      fetchFields();
      setErrorMessage("Prompt updated successfully.");
      setOpenSnack(true);
    } else {
      setErrorMessage("Could not update confidence.");
      setOpenSnack(true);
    }
  };
  if (!summaryFieldDocuments || dataTypesList.length < 1 || loading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "20vh" }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  return (
    <>
      <Grid container direction="row">
        <Grid item md="12">
          <Grid item xs="6">
            <h6>Fields</h6>
          </Grid>
          <Grid item xs="2">
            <Button
              variant="outlined"
              color="info"
              class="xrx-btn btn-default btn-block"
              component="label"
              onClick={addNew}
            >
              + new field
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: 2 }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Prompt</TableCell>

                <TableCell align="right">Validation Station</TableCell>
                <TableCell align="left">Data Type</TableCell>
                {/*<TableCell align="left">Type Of Grouping</TableCell>*/}
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {summaryFieldDocuments.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    {row.id === 0 ? (
                      <TextField
                        defaultValue={row.fieldName}
                        size="small"
                        color="secondary"
                        onChange={(e) => {
                          setEditedFieldName(e.target.value);
                        }}
                      ></TextField>
                    ) : (
                      <TextField
                        defaultValue={row.fieldName}
                        size="small"
                        color="secondary"
                        disabled="true"
                      ></TextField>
                    )}
                  </TableCell>
                  <TableCell>
                    {row.id === 0 ? (
                      <TextField
                        size="small"
                        color="secondary"
                        style={{ width: 500 }}
                        defaultValue={row.labelName}
                        onChange={(e) => {
                          setEditedLabel(e.target.value);
                        }}
                      ></TextField>
                    ) : (
                      <TextField
                        size="small"
                        color="secondary"
                        style={{ width: 500 }}
                        defaultValue={row.labelName}
                        onBlur={(e) => {
                          handlePrompt(e, row.id);
                        }}
                      ></TextField>
                    )}
                  </TableCell>

                  <TableCell align="right">
                    {row.id === 0 ? (
                      <Checkbox
                        defaultChecked={row.selected}
                        color="secondary"
                        onChange={(e) => {
                          setEditedSelected(e.target.checked);
                        }}
                      />
                    ) : (
                      <Checkbox
                        defaultChecked={row.selected}
                        color="secondary"
                        onChange={(e) => {
                          handleSelected(e, row.id);
                        }}
                      />
                    )}
                  </TableCell>

                  <TableCell align="left">
                    {row.id === 0 ? (
                      <FormControl>
                        <Select
                          labelId="model-select-label"
                          id="model-select"
                          label=""
                          defaultValue={row.dataTypeName}
                          onChange={(e) => {
                            returnIndex(e.target.value);
                          }}
                          color="info"
                          sx={{ width: 200 }}
                        >
                          {dataTypesList}
                        </Select>
                      </FormControl>
                    ) : (
                      <FormControl>
                        <Select
                          labelId="model-select-label"
                          id="model-select"
                          label=""
                          defaultValue={row.dataTypeName}
                          onChange={(e) => {
                            handleDataTypeName(e, row.id);
                          }}
                          color="info"
                          sx={{ width: 200 }}
                        >
                          {dataTypesList}
                        </Select>
                      </FormControl>
                    )}
                  </TableCell>
                  {/*
                <TableCell align="left">
                  <FormControl>
                    <Select
                      labelId="model-select-label"
                      id="model-select"
                      label=""
                      defaultValue={row.typeOfGroupingName}
                      onChange={(e) => {}}
                      color="info"
                      sx={{ width: 200 }}
                    >
                      {typeOfGroupingList}
                    </Select>
                  </FormControl>
                </TableCell> */}
                  <TableCell align="center">
                    {row.id === 0 ? (
                      <>
                        <IconButton
                          color="secondary"
                          aria-label="save row"
                          onClick={(event) =>
                            handleSave(event, row.id, row.dataTypeName)
                          }
                        >
                          <SaveIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          aria-label="delete row"
                          onClick={(event) => handleDelete(event, row.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton
                        color="secondary"
                        aria-label="delete row"
                        onClick={(event) => handleFieldDelete(event, row.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnack}
          onClose={handleCloseSnack}
          message={errorMessage}
          autoHideDuration={3000}
        />
      </Grid>
    </>
  );
}
export default LLMFIelds;
