import React, { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import {
  Chart,
  PieSeries,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { Palette } from "@devexpress/dx-react-chart";
import { Tooltip } from "@devexpress/dx-react-chart-material-ui";
import { Animation } from "@devexpress/dx-react-chart";
import Snackbar from "@mui/material/Snackbar";
import { userInformation } from "../../api/general";
import { GetApps } from "../../api/localServices";
import { AvailableCredit } from "../../api/localServices";
import { QtyProcessedDocuments } from "../../api/dashboard";

import DeleteIcon from "@mui/icons-material/Delete";
import PencilIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { GetDocuments } from "../../api/document";
import { GetDocumentsPending } from "../../api/document";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";
import FormControl from "@mui/material/FormControl";
import { IconButton } from "@mui/material";
import {
  GetConnectorSource,
  GetConnectorSourceout,
  GetConnectorSourcebyid,
} from "../../api/sources";
import { GetApplicationCompany } from "../../api/applications";
import { GetApplicationType } from "../../api/applications";
import { DeleteApp } from "../../api/applications";
import { DeleteSource } from "../../api/sources";
import { GetAppById } from "../../api/applications";
import { UpdateApplicationCompany } from "../../api/applications";
import { InsertApplicationCompany } from "../../api/applications";
import { SaveConnectorSourcein } from "../../api/sources";
import { SaveConnectorSourceout } from "../../api/sources";
import { GetConnectorTypeListin } from "../../api/sources";
import { GetConnectorTypeListout } from "../../api/sources";
import { GetFieldsByConnectorType } from "../../api/sources";
import DoneIcon from "@mui/icons-material/Done";
import { useConfirm } from "material-ui-confirm";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

var _ = require("lodash");
DataTable.use(DT);
function loadApp(id) {
  console.log(id);
}
function OutputSourcesConfiguration() {
  const columns2 = [
    { data: "sourceName" },

    { data: "applicationCompanyName" },
    { data: "connectorTypeName" },

    { data: "id" },
  ];
  const columns = [
    { data: "applicationCompanyName" },
    { data: "applicationTypeName" },
    { data: "clientID" },
    { data: "tenantID" },
    { data: "id" },
  ];
  const [summaryFieldDocuments, setSummaryFieldDocuments] = useState([]);
  const [connectorSources, setConnectorSources] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const [PassValue, setPassValue] = useState(""); // useState hook
  const [EmailValue, setEmailValue] = useState(""); // useState hook
  const [applications, setApplications] = useState([]);
  const [emailInputClass, setEmailInputClass] = useState("xrx-input");
  const [user, setUser] = useState([]);
  const [credit, setCredit] = useState([]);
  const [qtyProcessedDocs, setQtyProcessedDocs] = useState();
  const [appdata, setappdata] = useState();
  const [data, setdata] = useState();
  const [fielddata, setfielddata] = useState([]);
  const [fieldtosend, setfieldtosend] = useState([
    {
      Id: "",
      FieldValue: "",
    },
  ]);
  const [data2, setdata2] = useState();
  const [InputValue, setInputValue] = useState(" "); // useState hook
  const [InputAppValue, setInputAppValue] = useState(); // useState hook
  const [InputConValue, setInputConValue] = useState(); // useState hook
  const [InputContempValue, setInputContempValue] = useState(); // useState hook
  const [InputConnectorValue, setInputConnectorValue] = useState(" "); // useState hook
  const [InputSourceNameValue, setInputSourceNameValue] = useState(" "); // useState hook
  const [InputAppTypeValue, setInputAppTypeValue] = useState("Initial"); // useState hook
  const [InputClientIDValue, setInputClientIDValue] = useState(" "); // useState hook
  const [InputTenantValue, setInputTenantValue] = useState(" "); // useState hook
  const [AppSelect, setAppSelect] = useState(" "); // useState hook
  const [AppIDValue, setAppIDValue] = useState(" "); // useState hook
  const [AppSourceIDValue, setSourceIDValue] = useState(" "); // useState hook
  const [ConnectorList, setConnectorList] = useState(); // useState hook
  const confirm = useConfirm();
  useEffect(() => {
    (async () => {
      const AppSelect = await GetApplicationType();
      setAppSelect(Array.from(AppSelect));
    })();
  }, []);
  function ChildModal() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    function doEvent(obj, event) {
      var event = new Event(event, { target: obj, bubbles: true });
      return obj ? obj.dispatchEvent(event) : false;
    }
    return (
      <React.Fragment>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style }}>
            <h5 id="child-modal-title">Save Changes</h5>
            <p id="child-modal-description">
              Please enter your credentials to continue.
            </p>
            <label for="example9" class="">
              Email
            </label>
            <div id="  xrx-input">
              <div class="input-group ">
                <input
                  width="400"
                  onChange={(event) => setEmailValue(event.target.value)}
                  id="email"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <label for="example9">Password</label>
            <div id="  xrx-input">
              <div class="input-group  ">
                <input
                  width="400"
                  onChange={(event) => setPassValue(event.target.value)}
                  id="email"
                  type="password"
                  class="form-control"
                />
              </div>
            </div>
            <br></br>
            <div></div>
            <FormControl fullWidth>
              <Button onClick={handleClose} class="xrx-btn  ">
                Cancel
              </Button>
              <Button onClick={handleSaveApp} class="xrx-btn btn-primary">
                Save
              </Button>
            </FormControl>
          </Box>
        </Modal>
      </React.Fragment>
    );
  }

  useEffect(() => {
    // applicationCompanyName: "AnyDoc U1"
    // applicationTypeId: 1
    // applicationTypeName: "Microsoft Application"
    // clientID: "ec91c642-16a7-40f3-8477-b29da64773d5"
    // id: 2
    // tenantID: "f255fd07-55ef-4cab-8266-7cf59239b70a"

    (async () => {
      const data = await GetApplicationCompany();
      console.log("GetApplicationCompany", data);
      if (data.qty < 1) {
        setApplications([]);
      } else {
        setApplications(data.results);
      }
    })();
  }, []);

  const handleConnectorChange = async (id) => {
    setInputConValue(id);
    console.log(id);

    const FieldData = await GetFieldsByConnectorType(id);
    setfieldtosend([]);
    console.log("GetApplicationCompanyfield", FieldData);
    setfielddata(FieldData);
    if (FieldData.length < 1) {
    } else {
      console.log(fielddata);
    }
  };

  const handleAddToFields = (value, id) => {
    fieldtosend.push({
      ConnectorTypeFieldId: parseInt(id),
      FieldValue: value,
    });
    setfieldtosend(Array.from(new Set(fieldtosend)));
    fieldtosend?.map((data, index) => {
      if (id == data.ConnectorTypeFieldId) {
        console.log(fieldtosend.length, index);
        fieldtosend.splice(index, 1);

        fieldtosend.push({
          ConnectorTypeFieldId: parseInt(id),
          FieldValue: value,
        });
      } else {
        if (index == fieldtosend.length) {
          console.log(fieldtosend.length, index);
        }
      }
    });

    // the slice method will limit the output to 4 items only, considering only the indexes that meet the condition

    setfieldtosend(Array.from(new Set(fieldtosend)));
    console.log(Array.from(new Set(fieldtosend)));
  };

  const handleDelete = async (event, id) => {
   
   await confirm({ description: `This will permanently delete the App.`,confirmationButtonProps:{className:'xrx-btn btn-primary'},cancellationButtonProps:{className:'xrx-btn btn-secondary'}})
    .then   (() => {  console.log("  Deleted"); const removeApp =  DeleteApp(id);
      if (removeApp.status === 200) {
       const data =   GetApplicationCompany();
        console.log("GetApplicationCompany", data);
        if (data.qty < 1) {
          setApplications([]);
        } else {
          setApplications(data.results);
        }
      } else {
      }})
    .catch(() => console.log(" no Deleted"));

  
      const data = await  GetApplicationCompany();
       console.log("GetApplicationCompany", data);
      
         setApplications(data.results);
        

  
  };
  const handleDeleteSource = async (event, id) => {

  

    await confirm({ description: `This will permanently delete the Source.`,confirmationButtonProps:{className:'xrx-btn btn-primary'},cancellationButtonProps:{className:'xrx-btn btn-secondary'}})
    .then   (() => {  console.log("  Deleted");     const removeApp =  DeleteSource(id);

      const data2 =  GetConnectorSourceout();
  })
    .catch(() => console.log(" no Deleted"));

    const data2 = await GetConnectorSourceout();
        
    if (data2.length < 1) {
      setConnectorSources([]);
    } else {
      setConnectorSources(data2.results);
    }








  };

  const handleSaveApp = async () => {
    if (AppIDValue > 0) {
      console.log("edit");

      const EditApp = await UpdateApplicationCompany(
        AppIDValue,
        InputConnectorValue,
        InputValue,
        InputTenantValue,
        InputClientIDValue,
        EmailValue,
        PassValue
      );

      const data = await GetApplicationCompany();
      console.log("GetApplicationCompany", data);
      if (data.qty < 1) {
        setApplications([]);
      } else {
        setApplications(data.results);
      }
    } else {
      console.log("new");
      const SaveApp = await InsertApplicationCompany(
        InputConnectorValue,
        InputValue,
        InputTenantValue,
        InputClientIDValue,
        EmailValue,
        PassValue
      );

      const data = await GetApplicationCompany();
      console.log("GetApplicationCompany", data);
      if (data.qty < 1) {
        setApplications([]);
      } else {
        setApplications(data.results);
      }
    }

    setOpen(false);
  };

  const handleSaveSource = async () => {
    const EditApp = await SaveConnectorSourceout(
      AppSourceIDValue,
      InputSourceNameValue,
      InputAppValue,
      InputConValue,
      fieldtosend
    );

    setOpen2(false);
    const data2 = await GetConnectorSourceout();

    if (data2.length < 1) {
      setConnectorSources([]);
    } else {
      setConnectorSources(data2.results);
    }
  };

  const handleEdit = async (event, id) => {
    event.preventDefault();
    if (id > 0) {
      console.log("get datsa");
      const GetApp = await GetAppById(id);
      if (GetApp.status === 200) {
        const data = await GetApplicationCompany();
        console.log("get data", GetApp.results);

        setOpen(true);
      } else {
        console.log(GetApp);
        setOpen(true);

        console.log(GetApp.applicationCompanyName);
        setInputValue(GetApp.applicationCompanyName);
        setInputConnectorValue(GetApp.applicationTypeId);
        setInputAppTypeValue(GetApp.applicationTypeName);
        setInputClientIDValue(GetApp.clientID);
        setInputTenantValue(GetApp.tenantID);
        setAppIDValue(GetApp.id);
        setEmailValue(GetApp.userName);
      }
    } else {
      setOpen(true);

      console.log("");
      setInputValue("");
      setInputConnectorValue("");
      setInputAppTypeValue("");
      setInputClientIDValue("");
      setInputTenantValue("");
      setEmailValue("");
      setAppIDValue("0");
    }
  };

  const handleEditSources = async (event, id) => {
    event.preventDefault();
    if (id > 0) {
      console.log("get datssa");
      const datasource = await GetConnectorSourcebyid(id);
      console.log(datasource);
      setOpen2(true);
      setInputSourceNameValue(datasource.sourceName);
      setInputAppValue(datasource.applicationCompanyID);
      setInputConValue(datasource.connectorTypeID);
      setSourceIDValue(id);
      const FieldData = datasource.fields;
      setfieldtosend([]);
      console.log("GetApplicationCompany", FieldData);
      setfielddata(datasource.fields);
    } else {
      setInputSourceNameValue("");
      setInputAppValue("");
      setInputConValue("");
      setfielddata([]);
      setfieldtosend([]);
      setOpen2(true);
      event.preventDefault();
      setSourceIDValue(0);
    }
  };

  useEffect(() => {
    (async () => {
      const data2 = await GetConnectorSourceout();

      if (data2.length < 1) {
        setConnectorSources([]);
      } else {
        setConnectorSources(data2.results);
        console.log(data2.results);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const datax = await GetConnectorTypeListout();

      if (datax.length < 1) {
        setConnectorList([]);
      } else {
        setConnectorList(datax);
        console.log("conn list", datax);
      }
    })();
  }, []);

  useEffect(() => {
    // applicationCompanyName: "AnyDoc U1"
    // applicationTypeId: 1
    // applicationTypeName: "Microsoft Application"
    // clientID: "ec91c642-16a7-40f3-8477-b29da64773d5"
    // id: 2
    // tenantID: "f255fd07-55ef-4cab-8266-7cf59239b70a"

    (async () => {
      const data = await GetApplicationCompany();
      console.log("GetApplicationCompany", data);
      if (data.qty < 1) {
        setApplications([]);
      } else {
        setApplications(data.results);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const currentUser = await userInformation();
      setUser(currentUser);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const qtyDocs = await QtyProcessedDocuments();

      setQtyProcessedDocs(qtyDocs);
    })();
  }, []);

  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    (async () => {
      const getdocuments = await GetDocuments();
      const arrayDocuments = Array.from(getdocuments);
      setDocuments(arrayDocuments.length);
      //console.log(arrayDocuments);
      var filterDate = new Date();

      filterDate.setMonth(filterDate.getMonth() - 10);
      const filteredArray = arrayDocuments.filter((document) => {
        let date = new Date(document.dateCreated).getTime();

        return date >= filterDate;
      });
      /* const modifiedArray = _.groupBy(filteredArray, ({ dateCreated }) =>
        new Date(dateCreated).getMonth()
      );
      console.log(modifiedArray);
      */
    })();
  }, []);

  const [pending, setPending] = useState("0");

  useEffect(() => {
    (async () => {
      const getpending = await GetDocumentsPending();
      const arrayPending = Array.from(getpending);
      const date = new Date();

      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const arrayPendingFiltered = arrayPending.filter(
        (f) => f.dateUpdated.includes(month) && f.dateUpdated.includes(year)
      );
      setPending(arrayPendingFiltered.length);
    })();
  }, []);

  if (!qtyProcessedDocs) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "90vh" }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  return (
    
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        //alignItems="center"
        justifyContent="start"
        style={{ minHeight: "90vh", padding: "5vh", paddingTop: "1vh" }}
      >
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}></Grid>
          <Grid item md={12} xs={12}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <Typography variant="h5" m={6}>
                Applications
                <Button
                  className="float-right xrx-btn btn-primary"
                  onClick={(event) => handleEdit(event, 0)}
                >
                  <i class="xgl-plus xglsize-24"></i>
                  New App
                </Button>
              </Typography>

              <Grid>
                <DataTable
                  columns={columns}
                  data={applications}
                  className="display"
                  slots={{
                    4: (data, row) => (
                      <div>
                        <IconButton
                          color="info"
                          aria-label="delete row"
                          id={data}
                          onClick={(event) => handleEdit(event, data)}
                          sx={{ textDecoration: "none" }}
                        >
                          <PencilIcon />
                        </IconButton>
                        <IconButton
                          color="info"
                          aria-label="delete row"
                          onClick={(event) => handleDelete(event, data)}
                          sx={{ textDecoration: "none" }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    ),
                  }}
                >
                  <thead>
                    <tr>
                      <th>App Name</th>
                      <th>App Type</th>
                      <th>Client Id</th>
                      <th>Tenant Id</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                </DataTable>
              </Grid>
            </Card>
          </Grid>
          <Grid item md={12} xs={12}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <Typography variant="h5" m={6}>
                Output Sources
                <Button
                  className="float-right xrx-btn btn-primary"
                  onClick={(event) => handleEditSources(event, 0)}
                >
                  <i class="xgl-plus xglsize-24"></i>
                  New Source
                </Button>
              </Typography>

              <Grid>
                <DataTable
                  className="display"
                  columns={columns2}
                  data={connectorSources}
                  slots={{
                    3: (data, row) => (
                      <div>
                        <IconButton
                          color="info"
                          aria-label="delete row"
                          id={data}
                          sx={{ textDecoration: "none" }}
                          onClick={(event) => handleEditSources(event, data)}
                        >
                          <PencilIcon />
                        </IconButton>
                        <IconButton
                          color="info"
                          sx={{ textDecoration: "none" }}
                          aria-label="delete row"
                          onClick={(event) => handleDeleteSource(event, data)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    ),
                  }}
                >
                  <thead>
                    <tr>
                      <th>Source Name</th>
                      <th>App Name</th>
                      <th>Connector Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                </DataTable>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <h5 id="parent-modal-title">Apps Configuration</h5>
          <label for="example9" class="input-label">
            Name
          </label>
          <div id="name-input" class={emailInputClass}>
            <div class="input-group ">
              <input
                width="400"
                value={InputValue}
                onChange={(event) => setInputValue(event.target.value)}
                id="email"
                class="form-control"
              />
            </div>
          </div>
          <label for="example9" class="input-label">
            Connector
          </label>
          <FormControl fullWidth>
            <Select
              value={InputConnectorValue}
              onChange={(event) => setInputConnectorValue(event.target.value)}
            >
              {Array.from(AppSelect)?.map((AppSelect) => (
                <MenuItem
                  key={AppSelect.applicationTypeId}
                  value={AppSelect.applicationTypeId}
                >
                  {AppSelect.name}
                </MenuItem>
              ))}
              ;
            </Select>
          </FormControl>

          <label for="example9" class="input-label" style={{ display: "none" }}>
            App Type
          </label>
          <div
            id="email-input"
            class={emailInputClass}
            style={{ display: "none" }}
          >
            <div class="input-group ">
              <input
                width="400"
                value={InputAppTypeValue}
                onChange={(event) => setInputAppTypeValue(event.target.value)}
                id="email"
                type="text"
                class="form-control"
              />
              <input
                style={{ display: "none" }}
                width="400"
                value={AppIDValue}
                onChange={(event) => setAppIDValue(event.target.value)}
                id="email"
                type="text"
                class="form-control"
              />
            </div>
          </div>

          <label for="example9" class="input-label">
            Client Id
          </label>
          <div id="email-input" class={emailInputClass}>
            <div class="input-group ">
              <input
                width="400"
                value={InputClientIDValue}
                onChange={(event) => setInputClientIDValue(event.target.value)}
                id="email"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <label for="example9" class="input-label">
            Tenant
          </label>
          <div id="email-input" class={emailInputClass}>
            <div class="input-group ">
              <input
                width="400"
                value={InputTenantValue}
                onChange={(event) => setInputTenantValue(event.target.value)}
                id="email"
                type="text"
                class="form-control"
              />
            </div>
          </div>

          <label for="example9" class="">
            Email
          </label>
          <div id="email-input" class={emailInputClass}>
            <div class="input-group ">
              <input
                width="400"
                value={EmailValue}
                onChange={(event) => setEmailValue(event.target.value)}
                id="email"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <label for="example9">Password</label>
          <div id="email-input" class={emailInputClass}>
            <div class="input-group  ">
              <input
                width="400"
                onChange={(event) => setPassValue(event.target.value)}
                id="email"
                type="password"
                class="form-control"
              />
            </div>
          </div>
          <br></br>

          <FormControl fullWidth>
            <Button onClick={handleSaveApp} class="xrx-btn  ">
              Save App
            </Button>
          </FormControl>

          <ChildModal />
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <h5 id="parent-modal-title">Source Configuration</h5>
          <label for="example9" class="input-label">
            Name
          </label>
          <div id="email-input" class={emailInputClass}>
            <div class="input-group ">
              <input
                style={{ display: "none" }}
                width="400"
                value={AppIDValue}
                onChange={(event) => setSourceIDValue(event.target.value)}
                id="email"
                type="text"
                class="form-control"
              />
              <input
                width="400"
                value={InputSourceNameValue}
                onChange={(event) =>
                  setInputSourceNameValue(event.target.value)
                }
                id="email"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <label for="example9" class="input-label">
            Application
          </label>
          <FormControl fullWidth>
            <Select
              value={InputAppValue}
              onChange={(event) => setInputAppValue(event.target.value)}
            >
              {
                // --> Notice below changes
                // --> Changes from `options.length ? ...` to `options.length > 0 && ...`
                Array.from(applications).map((applications, i) => {
                  return (
                    <MenuItem key={applications.id} value={applications.id}>
                      {applications.applicationCompanyName}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
          <label for="example9" class="input-label">
            Integration
          </label>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              value={InputConValue}
              onChange={(event) => handleConnectorChange(event.target.value)}
              label="Integration"
            >
              {
                // --> Notice below changes
                // --> Changes from `options.length ? ...` to `options.length > 0 && ...`
                ConnectorList?.map((connector, i) => {
                  return (
                    <MenuItem key={connector.id} value={connector.id}>
                      {connector.connectorTypeName}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
          <label for="example9" class="input-label">
            Connector Fields
          </label>
          <br></br>
          {fielddata?.map((connector, i) => {
            return (
              <React.Fragment key={connector.connectorTypeFieldId}>
                <label for="example9" class="input-label">
                  {connector.fieldName}
                </label>
                <div id="email-input" class={emailInputClass}>
                  <div class="input-group  ">
                    <input
                      class="form-control"
                      onChange={(event) =>
                        handleAddToFields(event.target.value, event.target.id)
                      }
                      defaultValue={connector?.fieldValue}
                      id={connector?.connectorTypeFieldId}
                      placeholder={connector.fieldName}
                      type={connector.fieldTypeName}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          })}
          <br></br>

          <FormControl fullWidth>
            <Button onClick={handleSaveSource} class="xrx-btn  ">
              Save Source
            </Button>
          </FormControl>

          <ChildModal />
        </Box>
      </Modal>
    </>
  );
}

export default OutputSourcesConfiguration;
