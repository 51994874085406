import axiosApiInstance from "./axiosApiInstance";
const apiURL = process.env.REACT_APP_AUTH_API_URL;

export async function QtyProcessedDocuments() {
  const url = apiURL + "/api/Dashboard/QtyProcesedDocuments";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  const body = {
    StartDate: "2022-01-01",
    EndDate: "2025-12-31",
  };
  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function AvailableCredit() {
  const url = apiURL + "/api/Dashboard/AvailableCredit";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function UsageDashboard(startDate, endDate) {
  const url = apiURL + "/api/Dashboard/UsageDashboard";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  const body = {
    StartDate: startDate,
    EndDate: endDate,
  };
  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}
